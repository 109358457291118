import React from "react";
import './ErrorPage.css'


const ErrorPage = () => {
  return (
    <div className="errorPage">
      <h1>404 Page not found</h1>
    </div>
  )
}

export default ErrorPage;
